<template>
  <!-- 点位管理 -->
  <div class="gatekeeper-admin">
    <div class="companySearch">
      <div>
        <el-button
          v-if="isDis === 1"
          @click="addDoorClick"
          class="titleButton"
          type="primary"
          icon="el-icon-plus"
          >添加点位</el-button
        >
      </div>
      <div class="titleSearch">
        <div class="titleSear">
          <el-select
            v-if="isDis !== 2"
            @change="natureChange"
            @blur="natureChange"
            @keyup.enter.native="comSearchFun"
            :filter-method="myAddcomFocus"
            ref="select"
            v-model="region"
            placeholder="所属场所"
            filterable
            clearable
            :loading="companyloading"
          >
            <el-option
              v-for="(item, index) in companys"
              :key="index"
              :label="item.placeName"
              :value="item.placeName"
            >
            </el-option>
          </el-select>
          <i class="el-icon-search iconSearch" @click="comSearchFun"></i>
        </div>
        <el-select
          class="natureSty"
          v-model="nature"
          placeholder="设备类型"
          clearable
        >
          <el-option
            v-for="(item, index) in natures"
            :key="index"
            :label="item.remark"
            :value="item.appType"
          >
          </el-option>
        </el-select>
        <el-select
          class="stateSty"
          v-model="state"
          placeholder="点位状态"
          clearable
        >
          <el-option
            v-for="(item, index) in states"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-input
          @keyup.enter.native="searchClick"
          clearable
          v-model="input"
          placeholder="点位名称/SN码"
        ></el-input>
        <el-button
          @click="searchClick"
          class="titleButton"
          type="primary"
          icon="el-icon-search"
          >搜索</el-button
        >
        <el-button
          @click="exportClick"
          class="titleButton"
          icon="el-icon-download"
          :loading="exportLoad"
          >导出数据
          <a title="data"></a>
        </el-button>
      </div>
    </div>
    <div class="companyTableC">
      <div class="companyTable">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          height="100%"
        >
          <el-table-column
            prop="id"
            label="点位ID"
            align="center"
            min-width="80"
          >
          </el-table-column>
          <el-table-column prop="placeName" label="所属场所" min-width="180">
          </el-table-column>
          <el-table-column prop="doorName" label="点位名称" min-width="90">
          </el-table-column>
          <!-- <el-table-column
            prop="type"
            label="设备类型"
            align="center"
            min-width="90"
          >
          </el-table-column> -->
          <el-table-column
            prop="deviceSn"
            label="设备SN"
            align="center"
            min-width="140"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="deviceVersion"
            label="设备版本"
            align="center"
            min-width="100"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="mac"
            label="MAC地址"
            align="center"
            min-width="120"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="userName"
            label="账号"
            align="center"
            min-width="140"
          >
          </el-table-column> -->

          <el-table-column
            prop="insertTime"
            label="注册时间"
            align="center"
            min-width="160"
          >
          </el-table-column>
          <el-table-column
            prop="comment"
            label="备注"
            align="center"
            min-width="140"
          >
          </el-table-column>
          <el-table-column label="是否到期" align="center" min-width="140">
            <template slot-scope="scope">
              <div v-if="Date.parse(scope.row.expireTime) > new Date()">否</div>
              <div v-else>是</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="isDelA"
            label="点位状态"
            align="center"
            min-width="80"
          >
          </el-table-column>
          <el-table-column v-if="isDis === 1" label="操作" width="60">
            <template slot-scope="scope">
              <el-dropdown
                placement="bottom"
                @command="
                  (command) => handleCommand(command, scope.$index, scope.row)
                "
              >
                <el-button type="text" class="el-dropdown-link">
                  更多
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit">编辑</el-dropdown-item>
                  <el-dropdown-item command="edit123">详情</el-dropdown-item>
                  <!-- <el-dropdown-item command="activation"
                    >激活设备</el-dropdown-item
                  > -->
                  <el-dropdown-item command="isDel"
                    >{{ scope.row.isDel === 0 ? "停用" : "启用" }}
                  </el-dropdown-item>
                  <el-dropdown-item command="relieve"
                    >解除绑定</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        :current-page="currentPage"
        :page-size="pagingData.pageSize"
        @current-change="handleCurrentChange"
        background
        layout="total, prev, pager, next"
        :total="pagingData.totalRecords"
      >
      </el-pagination>
    </div>
    <!-- 弹窗-解除绑定 -->
    <relievePop
      v-if="relievePopShow"
      @cancelRelieve-fun="cancelRelieveFun"
      @determineRelieve-fun="determineRelieveFun"
      :companyName="companyName"
      :placeId="tableData[deleteIndex].id"
    ></relievePop>
    <!-- 弹窗-添加/编辑点位 -->
    <addEditGate
      v-if="addEditGateShow"
      @cancelGate-fun="cancelGateFun"
      @determineGate-fun="determineGateFun"
      :catdTitle="catdTitle"
      :companyData="catdTitle === '编辑点位' ? tableData[deleteIndex] : {}"
      :doorId="catdTitle === '编辑点位' ? tableData[deleteIndex].id : null"
    ></addEditGate>
    <!-- 是否显示详情 -->
    <addEditGate1
      v-if="addEditGateShow1"
      @cancelGate-fun="cancelGateFun"
      @determineGate-fun="determineGateFun"
      :catdTitle="catdTitle"
      :companyData="catdTitle === '详情点位' ? tableData[deleteIndex] : {}"
      :doorId="catdTitle === '详情点位' ? tableData[deleteIndex].id : null"
    ></addEditGate1>
    <!-- 弹窗-停用/启用弹窗 -->
    <enablePop
      v-if="enablePopShow"
      @enablePop-fun="enablePopFun"
      @determineEnable-fun="determineEnableFun"
      :enableName="companyName"
    ></enablePop>
  </div>
</template>
<script>
import relievePop from "../../components/relievePop";
import addEditGate from "../../components/addEditGate";
import addEditGate1 from "../../components/addEditGate1";
import enablePop from "../../components/enablePop";

export default {
  name: "gatekeeper-admin",
  components: {
    addEditGate,
    addEditGate1,
    relievePop,
    enablePop,
  },
  data() {
    return {
      loading: false,
      // 导出 loading
      exportLoad: false,
      // 加载所属场所
      companyloading: false,
      // 权限
      isDis: null,
      // 传递的弹窗标题
      catdTitle: "",
      // 被选择 所属场所
      myPlaceName: "",
      // 是否显示-弹窗-解除绑定
      relievePopShow: false,
      // 是否显示-弹窗-添加/编辑点位
      addEditGateShow: false,
      addEditGateShow1: false,
      // 是否显示-弹窗-启用/停用
      enablePopShow: false,
      // 删除点位 内容
      name: "的点位信息",
      // 所属场所
      region: "",
      companys: [],
      companyId: "",
      // 设备类型
      nature: null,
      // 设备类型 选择
      natures: [],
      // 状态
      state: "",
      states: ["停用", "启用"],
      // 搜索
      input: "",
      // 表格
      tableData: [],
      // 点位表格数据
      pagingData: {},
      // 被选中场所名称
      companyName: "",
      // 被选中场所下标
      deleteIndex: null,
      // 当前页数
      currentPage: 1,
      //列表-开始位置
      start: 1,
      // 被选中点位ID
      doorId: null,
    };
  },
  created() {
    // 不同权限 被访问到的页面
    let userInfo = sessionStorage.getItem("userInfo");
    this.isDis = Number(userInfo);

    if (JSON.parse(sessionStorage.getItem("seeCompanyName"))) {
      // 从场所跳转过来赋值
      let seeCompanyName = JSON.parse(sessionStorage.getItem("seeCompanyName"));
      this.region = seeCompanyName.placeName;
      this.companyId = seeCompanyName.id;
      sessionStorage.removeItem("seeCompanyName");
    }

    // 设备类型，用于下拉框
    this.getType();
  },
  methods: {
    // 设备类型，用于下拉框
    getType() {
      this.$instance
        .get("/v1/app_version/list", {
          params: {},
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.natures = res.data.data;
            // 点位表格
            this.gateList();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 所属场所-点击搜索图标
    comSearchFun() {
      this.companys = [];
      // 触发select获取焦点时
      this.$refs.select.focus();
      this.companyloading = true;
      this.getPlace();
    },

    // 所属场所-当 input 发生改变时
    myAddcomFocus(val) {
      this.region = val;
    },
    // 点击 更多下拉
    handleCommand(command, index, row) {
      if (command === "edit") {
        this.deleteIndex = index;
        // 是否显示-弹窗-编辑
        this.addEditGateShow = true;
        this.catdTitle = "编辑点位";
      } else if (command === "edit123") {
        this.deleteIndex = index;
        // 是否显示详情
        this.addEditGateShow1 = true;
        this.catdTitle = "详情点位";
      } else if (command === "activation") {
        this.postActivation(row.id);
      } else if (command === "isDel") {
        this.enablePopShow = true;
        this.doorId = row.id;
        this.deleteIndex = index;
        if (this.tableData[this.deleteIndex].isDel === 0) {
          this.companyName = "停用";
        } else if (this.tableData[this.deleteIndex].isDel === 1) {
          this.companyName = "启用";
        }
      } else if (command === "relieve") {
        this.companyName = row.placeName;
        this.deleteIndex = index;
        this.relievePopShow = true;
      }
    },
    postActivation(id) {
      this.$instance
        .post(" /v1/door/active?id=" + id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$message({
              message: "激活设备成功！",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 弹窗-启用/停用-点击取消
    enablePopFun() {
      this.enablePopShow = false;
    },
    // 弹窗-启用/停用-点击确定
    determineEnableFun() {
      if (this.tableData[this.deleteIndex].isDel === 0) {
        this.delPost();
      } else if (this.tableData[this.deleteIndex].isDel === 1) {
        this.enablePost();
      }
      this.enablePopShow = false;
    },
    // 启用状态
    enablePost() {
      this.$instance
        .post("/v1/door/enable?doorId=" + this.doorId, {})
        .then((res) => {
          if (res.data.status === 200) {
            this.tableData[this.deleteIndex].isDel = 0;
            this.gateList();
            this.$message({
              message: this.companyName + "成功！",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 停用状态
    delPost() {
      this.$instance
        .post("/v1/door/del?doorId=" + this.doorId, {})
        .then((res) => {
          if (res.data.status === 200) {
            this.tableData[this.deleteIndex].isDel = 1;
            this.gateList();
            this.$message({
              message: this.companyName + "成功！",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 点击 导出数据
    exportClick() {
      this.exportLoad = true;

      let state = null;
      if (this.state === "启用") {
        state = 0;
      } else if (this.state === "停用") {
        state = 1;
      }
      this.$instance
        .get("/v1/door/list-page-download", {
          params: {
            pageSize: 10000,
            // 状态
            state,
            // 所属场所
            placeId: this.region ? this.companyId : "",
            // 设备类型
            deviceType: this.nature ? this.nature : null,
            // 搜索关键字
            key: this.input,
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "data");
          } else {
            this.imatateDownloadByA(
              window.URL.createObjectURL(blob),
              "点位管理data"
            );
          }
          this.exportLoad = false;
        })
        .catch((error) => {
          console.log("error: ", error);
          this.exportLoad = false;
        });
    },
    //  @param {String} href
    //  @param {String} filename
    imatateDownloadByA(href, filename) {
      const a = document.createElement("a");
      a.download = filename;
      a.style.display = "none";
      a.href = href;
      // console.log('a.href: ', a.href)
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(href);
    },
    // 点击搜索
    searchClick() {
      this.currentPage = 1;
      this.gateList();
    },
    // 场所列表，用于下拉框
    getPlace() {
      this.$instance
        .get("/v1/place/search", {
          params: { placeName: this.region },
        })
        .then((res) => {
          // console.log('res: ', res.data.data)
          if (res.data.status === 200) {
            this.companyloading = false;
            this.companys = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 所属场所触发
    natureChange() {
      this.companys.map((val) => {
        if (this.region === val.placeName) {
          this.companyId = val.id;
        }
      });
    },
    // 点位列表
    gateList() {
      this.loading = true;

      let state = null;
      if (this.state === "启用") {
        state = 0;
      } else if (this.state === "停用") {
        state = 1;
      }
      this.$instance
        .get("/v1/door/list-page", {
          params: {
            pageNum: this.currentPage - this.start,
            pageSize: 10,
            // 状态
            state,
            // 所属场所
            placeId: this.region ? this.companyId : "",
            // 设备类型
            deviceType: this.nature ? this.nature : null,
            // 搜索关键字
            key: this.input,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.loading = false;
            let pagingData = res.data.data;
            let tableData = pagingData.elements;
            tableData.map((val) => {
              if (val.deviceType) {
                this.natures.map((vala) => {
                  if (vala.appType === val.deviceType) {
                    val.type = vala.remark;
                  }
                });
              } else {
                val.type = "未知";
              }
              if (val.isDel === 0) {
                val.isDelA = "启用";
              } else if (val.isDel === 1) {
                val.isDelA = "停用";
              }
            });
            this.pagingData = pagingData;
            this.tableData = tableData;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 点击分页-当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      // 搜索并拉取列表
      this.gateList();
    },
    // 弹窗-解除绑定-点击取消
    cancelRelieveFun() {
      this.relievePopShow = false;
    },
    // 弹窗-解除绑定-点击确定
    determineRelieveFun() {
      this.relievePopShow = false;
      // 对应数组下的sn至空
      this.tableData[this.deleteIndex].deviceSn = "";
    },
    // 添加点位
    addDoorClick() {
      this.addEditGateShow = true;
      this.catdTitle = "添加点位";
    },
    // 弹窗-编辑/添加-点击取消
    cancelGateFun() {
      this.addEditGateShow = false;
      this.addEditGateShow1 = false;
    },
    // 弹窗-编辑/添加-点击确定
    determineGateFun() {
      // 是否显示-弹窗-编辑
      this.addEditGateShow = false;
      this.addEditGateShow1 = false;
      // 搜索并拉取列表
      this.gateList();
    },
  },
};
</script>
<style lang="scss" scoped>
.gatekeeper-admin {
  width: calc(100% - 40px);
  margin: 20px;
  background-color: #fff;
  // 搜索
  & ::v-deep .companySearch {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    box-sizing: border-box;
    border-bottom: 1px #f0f0f0 solid;
    .el-button--primary {
      line-height: 32px;
      padding: 0 12px;
      border: unset;
    }
    .titleSearch {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .timeSty {
        margin-left: 10px;
        margin-right: 6px;
        .el-date-editor {
          height: 32px;
          width: 360px !important;
        }
        .el-input__icon,
        .el-range-separator {
          line-height: 32px;
          height: unset;
        }
      }
      // 所属场所
      .titleSear {
        position: relative;
        .el-select {
          .el-input__inner {
            padding-right: 46px;
          }
          .el-input__suffix {
            margin-right: 19px;
            .el-icon-arrow-up {
              display: none;
            }
            .el-input__suffix {
              margin-right: 19px;
              .el-icon-arrow-up {
                display: none;
              }
            }
          }
          .iconSearch {
            width: 24px;
            position: absolute;
            top: 9px;
            right: 12px;
            color: rgb(182, 182, 182);
            font-size: 14px;
            background-color: #fff;
            text-align: center;
            z-index: 2;
            cursor: pointer;
          }
          .el-input__suffix {
            margin-right: 19px;
            .el-icon-arrow-up {
              display: none;
            }
          }
        }
        .iconSearch {
          width: 24px;
          position: absolute;
          top: 9px;
          right: 12px;
          color: rgb(182, 182, 182);
          font-size: 14px;
          background-color: #fff;
          text-align: center;
          z-index: 2;
          cursor: pointer;
        }
        .iconSearch {
          width: 24px;
          position: absolute;
          top: 9px;
          right: 12px;
          color: rgb(182, 182, 182);
          font-size: 14px;
          background-color: #fff;
          text-align: center;
          z-index: 2;
          cursor: pointer;
        }
      }
      .natureSty > .el-input {
        width: 120px !important;
      }
      .stateSty > .el-input {
        width: 106px !important;
      }
      .el-select > .el-input,
      .el-input {
        margin-right: 6px;
      }
      .el-select > .el-input {
        width: 170px;
      }
      .titleButton {
        line-height: 30px;
        padding: 0 12px;
      }
      .el-input {
        width: 240px;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        .el-input__suffix {
          i {
            line-height: 32px;
          }
        }
      }
    }
  }
  .companyTableC {
    width: calc(100% - 40px);
    height: calc(100% - 144px);
    margin: 20px;
    // 表格
    & ::v-deep .companyTable {
      height: 100%;
      border: 1px #f0f0f0 solid;
      overflow: auto;
      .el-table td,
      .el-table th {
        height: 49px;
        padding: 4px 0;
      }
    }
  }
}
</style>
