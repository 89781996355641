<template>
    <!-- 解除绑定 -->
    <div class="relievePop">
        <div class="mask"></div>
        <div class="relievePopCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="cancelRelieveFun"></i>
            </div>
            <div class="carContent">
                是否将
                <span class="companySty">{{ companyName }}</span> 与
                <span class="companySty">设备SN</span>解除绑定？
            </div>
            <div class="button">
                <el-button @click="cancelRelieveFun">取 消</el-button>
                <el-button type="primary" @click="determineRelieveFun"
                    >确 定</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'relievePop',
    props: {
        companyName: {
            type: String,
            default: '',
        },
        // 场所id
        placeId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            catdTitle: '解除绑定',
        }
    },
    created() {},
    methods: {
        // 点击取消
        cancelRelieveFun() {
            this.$emit('cancelRelieve-fun')
        },
        // 点击确定
        determineRelieveFun() {
            this.postRelieve()
        },
        postRelieve() {
            this.$instance
                .post('/v1/door/unbind?id=' + this.placeId, {})
                .then((res) => {
                    // console.log('res: ', res.data)
                    if (res.data.status === 200) {
                        this.$message({
                            message:
                                '成功解除与' + this.companyName + '的绑定！',
                            type: 'success',
                        })
                        this.$emit('determineRelieve-fun')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.relievePop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
    // 弹窗卡片
    .relievePopCard {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 6px;
        // 头部
        .cardHeader {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 0 40px;
            box-sizing: border-box;
            border-radius: 6px 6px 0 0;
            // 头部-icon
            .el-icon-close {
                font-size: 24px;
            }
        }
        .carContent {
            padding: 20px;
            text-align: center;
            border-top: 1px #f0f0f0 solid;
            .companySty {
                color: #3399ff;
            }
        }
    }
    .button {
        padding: 20px 40px;
        display: flex;
        justify-content: flex-end;
        .el-button {
            line-height: 30px;
            padding: 0 20px;
        }
    }
}
</style>
