<template>
    <!-- 启用/停用 确认弹窗 -->
    <div class="enablePop">
        <div class="mask"></div>
        <div class="enablePopCard">
            <div class="carContent">
                是否
                <span class="companySty">{{ enableName }}</span> 该账号？
            </div>
            <div class="button">
                <el-button @click="enablePopFun">取 消</el-button>
                <el-button type="primary" @click="determineEnableFun">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'enablePop',
        props: {
            enableName: {
                type: String,
                default: '启用',
            },
        },
        data() {
            return {}
        },
        created() {},
        methods: {
            // 点击取消
            enablePopFun() {
                this.$emit('enablePop-fun')
            },
            // 点击确定
            determineEnableFun() {
                this.$emit('determineEnable-fun')
            },
        },
    }
</script>
<style lang="scss" scoped>
    .enablePop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        .enablePopCard {
            width: 320px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            .carContent {
                padding: 40px 40px 20px;
                text-align: center;
                .companySty {
                    color: #3399ff;
                }
            }
        }
        .button {
            padding: 20px;
            display: flex;
            justify-content: center;
            .el-button {
                line-height: 30px;
                margin: 0 20px;
                padding: 0 20px;
            }
        }
    }
</style>
